import React, { useState, useEffect } from 'react';
import { Button, Checkbox, MultiSelect } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const GitHubIntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepos, setSelectedRepos] = useState([]);
  const [allowPush, setAllowPush] = useState(false);

  useEffect(() => {
    fetch('/api/premium/github/status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsConnected(data.isConnected);
        if (data.isConnected) {
          const allRepos = data.repositories || [];
          const savedSelected = data.selectedRepos || [];
          
          setRepositories(allRepos);
          setSelectedRepos(savedSelected.map(sel => 
            allRepos.find(repo => repo.id === sel.id) || sel
          ).filter(Boolean));
          
          setAllowPush(data.allowPush || false);
        }
      })
      .catch(console.error);
  }, [token]);

  const handleConnect = () => {
    fetch('/api/premium/github/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.authUrl) window.location.href = data.authUrl;
        else throw new Error('No auth URL');
      })
      .catch(error => {
        console.error('GitHub connection error:', error);
        alert('Connection initiation failed');
      });
  };

  const handleDisconnect = () => {
    if (window.confirm('Disconnecting GitHub will revoke access and remove all synced data.')) {
      fetch('/api/premium/github/disconnect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          setIsConnected(false);
          setRepositories([]);
          setSelectedRepos([]);
          setAllowPush(false);
          alert('GitHub disconnected');
        })
        .catch(console.error);
    }
  };

  const handleSaveSettings = () => {
    const minimalRepos = selectedRepos.map(({ id, name, full_name }) => ({ id, name, full_name }));
    
    fetch('/api/premium/github/save-settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selectedRepos: minimalRepos,
        allowPush
      }),
    })
      .then(response => {
        if (!response.ok) throw new Error('Save failed');
        alert('Settings saved');
        navigate('/');
      })
      .catch(error => {
        console.error('Save error:', error);
        alert('Failed to save settings');
      });
  };

  return (
    <div className="github-integration-page">
      <div className="github-integration-container">
        <h1>GitHub Integration</h1>
        {!isConnected ? (
          <>
            <p>
              Connect your GitHub account to enable code collaboration and repository management.
              Fresh Focus AI will be able to access your repositories based on your permissions.
            </p>
            <p>
              Example commands: "Create a new branch for feature X", 
              "Review the latest commits in my project", or 
              "Create a pull request for the current changes"
            </p>
            <Button kind="primary" onClick={handleConnect}>
              Connect with GitHub
            </Button>
          </>
        ) : (
          <>
            <p>
              GitHub account connected. Select repositories to grant access:
            </p>
            <MultiSelect
              className="repo-select"
              id="repos-select"
              label="Available Repositories"
              items={repositories}
              itemToString={item => item?.full_name || ''}
              selectedItems={selectedRepos}
              onChange={({ selectedItems }) => setSelectedRepos(selectedItems)}
            />
            <Checkbox
              id="write-access"
              labelText="Allow write access (create branches, open PRs)"
              checked={allowPush}
              onChange={(_, { checked }) => setAllowPush(checked)}
            />
            <div className="button-group" style={{ marginTop: '2rem' }}>
              <Button kind="danger" onClick={handleDisconnect}>
                Disconnect GitHub
              </Button>
              <Button kind="secondary" onClick={() => navigate('/')}>
                Cancel
              </Button>
              <Button kind="primary" onClick={handleSaveSettings}>
                Save Settings
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GitHubIntegrationPage;